<template>
  <main class="ml-12">
    <div class="flex flex-row mt-12 mb-8 items-end justify-between">
      <div class="flex-col">
        <h2>Register</h2>
        <h3 class="font-bold mt-4 text-2xl ">Registers Details</h3>
      </div>
      <div class="flex-col w-2/12">
        <trac-button class="uppercase">{{ register.opened ? 'Close Register' : 'Open Register'}}</trac-button>
      </div>
    </div>
    <div class="flex flex-row gap-5 ">
      <div class="flex-column w-4/12">
        <div class="bg-white px-8 py-8  rounded-lg overflow-hidden big-shadow">
          <span class="text-xl font-semibold capitalize">{{ register.name }}</span>
          <ul class="mt-8 mb-8">
            <div class="flex flex-row space-x-10">
              <div class=" flex-column w-2/12">
                <span class="text-sm font-medium text-primaryGray"
                  >Status:</span
                >
              </div>
              <div class=" flex-column">
                <span v-if="register.opened" class="text-base font-semibold text-secondaryGreen "
                  >Open</span
                >
                <span v-else class="text-base font-semibold text-secondaryRed"
                  >Closed</span
                >
              </div>
            </div>
            <div class="flex flex-row space-x-10">
              <div class=" flex-column w-2/12">
                <span class="text-sm font-medium text-primaryGray">Store:</span>
              </div>
              <div class=" flex-column ">
                <span class="text-base font-semibold">Main Store</span>
              </div>
            </div>
            <div class="flex flex-row space-x-10">
              <div class=" flex-column w-2/12">
                <span class="text-sm font-medium text-primaryGray">Staff:</span>
              </div>
              <div class=" flex-column ">
                <span class="text-base font-semibold ">{{ register.assigned.name }}</span>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <div class="flex-column w-8/12">
        <div class="bg-white px-8 py-8 rounded-lg overflow-hidden big-shadow">
          <ul>
            <li class="text-xs text-primaryGray">Amount in Register</li>
            <li class="font-bold text-2xl mt-1">
              {{ register.amount | formatPrice }}
            </li>
            <li>
              <div class="flex flex-row mt-10 mb-8 gap-5">
                <trac-button variant="small">Add Cash</trac-button>
                <trac-button variant="small">Remove Cash</trac-button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bg-white px-8 py-8 mt-5 rounded-lg overflow-hidden big-shadow">
      <div class="flex flex-row gap-5 border-b pb-8">
        <div
          class=" w-3/12 flex-column bg-blue-100 px-8 py-8 rounded-lg overflow-hidden"
        >
          <ul>
            <li class="text-xs text-primaryGray">Opening Float</li>
            <li class="font-bold text-2xl mt-1">
              {{ register.opening_float | formatPrice }}
            </li>
          </ul>
        </div>
        <div
          class=" w-3/12  flex-column bg-blue-100 px-8 py-8 rounded-lg overflow-hidden"
        >
          <ul>
            <li class="text-xs text-primaryGray">Cash (Expected)</li>
            <li class="font-bold text-2xl mt-1">₦ 0.00</li>
          </ul>
        </div>
        <div
          class=" w-3/12  flex-column bg-blue-100 px-8 py-8 rounded-lg overflow-hidden"
        >
          <ul>
            <li class="text-xs text-primaryGray">Card</li>
            <li class="font-bold text-2xl mt-1">₦ 0.00</li>
          </ul>
        </div>
      </div>
      <div class="mt-8">
        <trac-dropdown></trac-dropdown>
      </div>
      <div class="w-6/12 mb-8 mt-5 border rounded-lg overflow-hidden ">
        <table class="w-full table-fixed ">
          <thead class="bg-blue-100 ">
            <tr class="h-20">
              <th class="text-left text-sm font-semibold uppercase pl-8">
                Register History
              </th>
              <th class="text-left text-xs font-semibold uppercase">
                <trac-button
                  variant="icon"
                  class="font-bold text-sm uppercase pr-8"
                  >View All</trac-button
                >
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              :class="index % 2 === 0 ? '' : 'bg-gray-100'"
              v-for="(timeline, index) in register.history"
              class="h-20"
              :key="index"
            >
              <td class="text-sm capitalize pl-8">
                <ul>
                  <li class="text-base font-medium">
                    {{ moment(timeline.date).format('ddd MMM D YYYY') }}
                  </li>
                  <li class="text-xs text-primaryGray">
                    {{ timeline.amount | formatPrice }}
                  </li>
                </ul>
              </td>
              <td class=" text-xs font-medium text-right pr-8">
                <trac-button variant="icon"></trac-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import {
  DELETE_LOCAL_DB_DATA,
  GET_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";
import moment from "moment";

export default {
  name: "RegistersDetail",
  data() {
    return {
      register: null,
      moment,
    };
  },
  created() {
    this.register = GET_LOCAL_DB_DATA("register-details");
    if (this.register) {
    } else {
      this.$router.replace({ name: "Registers" });
    }
  },
  destroyed() {
    DELETE_LOCAL_DB_DATA("register-details");
  },
};
</script>

<style scoped></style>
